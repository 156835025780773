
import { Component, Vue } from 'vue-property-decorator';
import Title from '@/components/common/Title.vue';
import OnboardLayout from '@/components/OnboardLayout.vue';

import { AuthService, RoundUpService, UserService } from '@/services';
import { FeatureModule, UserModule } from '@/store/modules';
import { mdiEye, mdiEyeOff } from '@mdi/js';
import { getHomeRoute } from '@/router/routes';

@Component({
  components: {
    Title,
    OnboardLayout
  }
})
export default class Login extends Vue {
  rememberMe = false;
  visible = false;

  form = {
    email: '',
    password: ''
  };

  /**
   * Login user
   */
  async onLogin(): Promise<void> {
    const { email, password } = this.form;

    try {
      await AuthService.login({ email, password });
      let user = await UserService.fetchUser();
      UserModule.setUser(user);
    } catch (e) {
      this.$notifyError(
        `We couldn't find an account matching the email and password you entered. 
        Please check your email and password and try again.`
      );

      return;
    }

    if (!UserModule.hasPaymentMethod) {
      this.$router.replace('/card-setup');
    } else {
      // NOTE: If only round up feature is enabled, we need to know whether user already
      // has round up rule created. Home page is different depending on that.
      if (FeatureModule.getIsRoundUpEnabled && !FeatureModule.getIsOffersEnabled) {
        await RoundUpService.getRule();
      }
      // NOTE: Don't reset submission state before refreshing page!

      const redirect = getHomeRoute();

      // Redirect to previous page (if necessary)
      const { redirectUrl = redirect } = this.$route.query;
      // Force a page refresh to better clean up app state
      // window.location.replace(redirectUrl as string);
      this.$router.replace(redirectUrl as string);
    }
  }

  get appendIcon(): string | null {
    return this.visible ? mdiEye : mdiEyeOff;
  }
  get type(): string {
    return this.visible ? 'text' : 'password';
  }

  onToggleVisibility(): void {
    this.visible = !this.visible;
  }
}
